import React, { Component } from "react";
import SignUpView from "./SignUpView";
import { withRouter } from "react-router";
import app from "../base";
import base from "../airtable";

class SignUpContainer extends Component {
  handleSignUp = async event => {
    event.preventDefault();
    const { email, password } = event.target.elements;
    try {
      const user = await app
        .auth()
        .createUserAndRetrieveDataWithEmailAndPassword(email.value, password.value);

      // We need to ensure the user record is created in airtable with the firebase user email
      const airbase = await base('Roadmap Data').create({
        "Email": email.value
      }, function(err, record) {
        if (err) { console.error(err); }
      });

      //Sign the user in so they can succesfully save data
      const signin = await app
        .auth()
        .signInAndRetrieveDataWithEmailAndPassword(email.value, password.value);

      this.props.history.push("/");
    } catch (error) {
      alert(error);
    }
  };

  render() {
    return <SignUpView onSubmit={this.handleSignUp} />;
  }
}

export default withRouter(SignUpContainer);

import React from "react";

const SignUpView = ({ onSubmit }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems:"center", padding:"40px"}}>
      <h1>RAM College Roadmap</h1>
      <div style={{ width: "400px"}}>
        <form onSubmit={onSubmit} style={{ width: "100%"}}>
          <label>
            <input
              style={{ width: "400px", marginBottom: "10px" }}
              className="input-box"
              name="email"
              type="email"
              placeholder="Email"
            />
          </label>
          <label>
            <input
              style={{ width: "400px", marginBottom: "10px" }}
              className="input-box"
              name="password"
              type="password"
              placeholder="Password"
            />
          </label>
          <div style={{ width: "425px", display: "flex", justifyContent: "center"}}>
            <button type="submit" className="submit-btn" style={{ width: "200px"}}>Sign Up</button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SignUpView;

import React, {Component} from "react";
import app from "./base";
import base from "./airtable";


class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleChange = this.handleChange.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  handleLogOut() {
    app.auth().signOut();
  }

  handleChange(event) {
    const target = event.target;
    const value = target.type === 'checkbox' ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }

  async onSubmit(event) {
    event.preventDefault();

    await base('Roadmap Data').update(this.state['formId'], this.state, function (err, record) {
      if (err) {
        console.error(err);
        return;
      } else {
        alert('Thanks. Your information has been saved.');
      }
    });
  }

  async componentDidMount() {
    await base('Roadmap Data').select({
      // Selecting the first 3 records in Grid view:
      maxRecords: 16,
      view: "Grid view",
      filterByFormula: "SEARCH(\"" + this.props.currentUser.email + "\", Email)"
    }).eachPage((records) => {
      const recordData = records[0].fields;
      let r = {};
      for (const key of Object.keys(recordData)) {
        // version can't be modified because it is computed by airtable
        if (key !== 'Version') {
          r[key] = recordData[key]
        }
      }
      // need the record id to call update
      r['formId'] = records[0].id;
      this.setState(r);

    }, function done(err) {
      if (err) {
        console.error(err);
        return;
      } else {
        //TODO make this a more user friendly error message
        alert('Roadmap Updated!');
      }
    });

  }

  render() {
    return (
      <div className="app-main">
        <h1>RAM College Roadmap</h1>
        <button className="submit-btn" onClick={this.handleLogOut}>Log Out</button>
        <form id="ramForm" name="ramForm" onSubmit={this.onSubmit}>
          {/** Personal Information */}
          <section>
              <table>
                <u><h3>Personal Information</h3></u>
                <tr>
                  <td>Advisor</td>
                  <td><select
                    className="input-box"
                    type="text"
                    name="Advisor"
                    value={this.state['Advisor']}
                    onChange={this.handleChange}
                  >
                    <option value="">--Please select your advisor--</option>
                    <option value="Dr. Erwin Cabrera">Dr. Erwin Cabrera</option>
                    <option value="Dr. Erica Friedman">Dr. Erica Friedman</option>
                    <option value="Dylan Gafarian">Dylan Gafarian</option>
                    <option value="Joanna Breitman">Joanna Breitman</option>
                  </select>
                  </td>
                </tr>
                <tr>
                  <td>First Name:</td>
                  <td><input 
                    className="input-box"
                    type="text" 
                    name="First Name"
                    value={this.state['First Name']}
                    onChange={this.handleChange}
                  /></td>
                </tr>
                <tr>
                  <td>Last Name:</td>
                  <td><input
                  className="input-box"
                  type="text"
                  name="Last Name"
                  value={this.state['Last Name']}
                  onChange={this.handleChange}
                  /></td>
                </tr>
                <tr>
                  <td>RAM ID:</td>
                  <td><input
                  className="input-box"
                  type="text"
                  name="Ram ID"
                  value={this.state['Ram ID']}
                  onChange={this.handleChange}
                  /></td>
                </tr>
                <tr>
                  <td>Phone:</td>
                  <td><input
                  className="input-box"
                  type="text"
                  name="Phone"
                  value={this.state['Phone']}
                  onChange={this.handleChange}
                  /></td>
                </tr>
                <tr>
                  <td></td> 
                  <td style={{ textAlign: "left" }}
                  >ex: (xxx) xxx-xxxx</td> 
                </tr>
                <tr>
                  <td>Address:</td>
                  <td><textarea 
                  className="text-area"
                  type="text"
                  name="Address"
                  value={this.state['Address']}
                  onChange={this.handleChange}
                  /></td>
                </tr>
                <tr>
                <td>Email:</td>
                  <td><input
                  className="input-box"
                  type="text"
                  name="Email"
                  value={this.state['Email']}
                  onChange={this.handleChange}
                  /></td>
                </tr>
                <tr>
                  <td></td> 
                  <td style={{ textAlign: "left" }}
                  >ex: yourEmail@email.com</td> 
                </tr>
                <tr>
                  <td>Major:</td>
                  <td><input
                  className="input-box"
                  type="text"
                  name="Major"
                  value={this.state['Major']}
                  onChange={this.handleChange}
                  /></td>
                </tr>
                <tr>
                  <td>Expected Graduation:</td>
                  <td><input
                  className="input-box"
                  type="text"
                  name="Expected Graduation Year"
                  value={this.state['Expected Graduation Year']}
                  onChange={this.handleChange}
                  /></td>
                </tr>
                <tr>
                  <td>Research Project:</td>
                  <td><textarea 
                  className="text-area"
                  type="text"
                  name="Research Project"
                  value={this.state['Research Project']}
                  onChange={this.handleChange}
                  ></textarea></td>
                </tr>
              </table>
          </section>

          {/** Long Term Goals - 1 */}
          <section>
            <table>
              <u><h3>Long Term Goal</h3></u>
              <tr>
                <td>What are my goals?</td>
                <td><textarea 
                className="text-area"
                type="text"
                name="Long Term Goal 1"
                value={this.state['Long Term Goal 1']}
                onChange={this.handleChange}
                ></textarea></td>
              </tr>
              <tr>
                <td>What are my interests?</td>
                <td><textarea 
                className="text-area"
                type="text"
                name="Long Interests"
                value={this.state['Long Interests']}
                onChange={this.handleChange}
                ></textarea></td>
              </tr>
              <tr>
                <td>How will I Achieve this?</td>
                <td><textarea 
                className="text-area"
                type="text"
                name="Long Plan to Achieve"
                value={this.state['Long Plan to Achieve']}
                onChange={this.handleChange}
                ></textarea></td>
              </tr>
              <tr>
                <td>Deadline:</td>
                {/* TODO(kaitlyn): add date picker component */}
                <td><input
                className="input-box"
                type="text"
                name="Long Target Completion Date"
                value={this.state['Long Target Completion Date']}
                onChange={this.handleChange}
                /></td>
              </tr>
              <tr>
                <td></td>
                <td 
                style={{ textAlign: "left" }}
                >ex: YYYY-MM-DD</td>
              </tr>
            </table>  
          </section>

          {/** Short Term Goals */}
          <section>
            <table>
              <u><h3>Short Term Goals</h3></u>
              <tr>
                <td>What are my goals?</td>
                <td><textarea 
                className="text-area"
                type="text"
                name="Short Term Goal"
                value={this.state['Short Term Goal']}
                onChange={this.handleChange}
                ></textarea></td>
              </tr>
              <tr>
                <td>What are my interests?</td>
                <td><textarea 
                className="text-area"
                type="text"
                name="Short Interests"
                value={this.state['Short Interests']}
                onChange={this.handleChange}
                ></textarea></td>
              </tr>
              <tr>
                <td>How will I Achieve this?</td>
                <td><textarea 
                className="text-area"
                type="text"
                name="Short Plan to Achieve"
                value={this.state['Short Plan to Achieve']}
                onChange={this.handleChange}
                ></textarea></td>
              </tr>
              <tr>
                <td>Deadline:</td>
                <td><input
                className="input-box"
                type="text"
                name="Short Target Completion Date"
                value={this.state['Short Target Completion Date']}
                onChange={this.handleChange}
                /></td>
              </tr>
              <tr>
                <td></td>
                <td 
                style={{ textAlign: "left" }}
                >ex: YYYY-MM-DD</td>
              </tr>
            </table>
          </section>

          {/** Study Abroad Interests */}
          <section>
            <u><h3>Study Abroad Interests</h3></u>
            <tr>
              <textarea 
                className="text-area"
                type="text"
                name="Study Abroad"
                value={this.state['Study Abroad']}
                onChange={this.handleChange}
                ></textarea>
            </tr>
          </section>

          {/** Year */}
          <section>
              <u><h3>Fall Courses</h3></u>
              <textarea 
                className="text-area"
                type="text"
                name="Courses"
                value={this.state['Courses']}
                onChange={this.handleChange}
              ></textarea>

              <u><h3>Extracurricular Activities/Clubs</h3></u>
              <textarea
                className="text-area"
                type="text"
                name="Extracurriculars"
                value={this.state['Extracurriculars']}
                onChange={this.handleChange}
              />

              <u><h3>Events</h3></u>
              <textarea
                className="text-area"
                type="text"
                name="Events"
                value={this.state['Events']}
                onChange={this.handleChange}
              />

              <u><h3>Jobs/Internships</h3></u>
              <textarea
                className="text-area"
                type="text"
                name="Jobs"
                value={this.state['Jobs']}
                onChange={this.handleChange}
                /><br />
            </section>
            <section>
              <table>
                <u><h3>Reflections</h3></u>
                <tr>
                  <td>Strengths:</td>
                  <td><textarea 
                    className="text-area"
                    type="text"
                    name="Strengths"
                    value={this.state['Strengths']}
                    onChange={this.handleChange}
                  /></td>
                </tr>
                <tr>
                  <td>Challenges:</td>
                  <td><textarea 
                    className="text-area"
                    type="text"
                    name="Challenges"
                    value={this.state['Challenges']}
                    onChange={this.handleChange}
                  /></td>
                </tr>
                <tr>
                  <td>What do I plan to do<br />differently next semester:</td>
                  <td><textarea 
                    className="text-area"
                    type="text"
                    name="Things Done Differently"
                    value={this.state['Things Done Differently']}
                    onChange={this.handleChange}
                  ></textarea></td>
                </tr>
                <tr>
                  <td>What I learned about myself:</td>
                  <td><textarea 
                    className="text-area"
                    type="text"
                    name="Lessons Learned"
                    value={this.state['Lessons Learned']}
                    onChange={this.handleChange}
                  ></textarea></td>
                </tr>
              </table>
            </section>
          <button className="submit-btn">Submit</button>
        </form>
      </div>
    )
  }

}

export default Home;

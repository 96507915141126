import React from "react";

const LogInView = ({ onSubmit }) => {
  return (
    <div style={{ display: "flex", flexDirection: "column", alignItems:"center", padding:"40px"}}>
      <h1>RAM College Roadmap</h1>
      <div style={{ width: "400px"}}>
        <form onSubmit={onSubmit} style={{ width: "100%"}}>
          <label>
            <input
              style={{ width: "400px", marginBottom: "10px" }}
              className="input-box"
              name="email"
              type="email"
              placeholder="Email"
            />
          </label>
          <label>
            <input
              style={{ width: "400px", marginBottom: "10px" }}
              className="input-box"
              name="password"
              type="password"
              placeholder="Password"
            />
          </label>
          <div style={{ display: "flex", justifyContent: "center"}}>
            <div style={{ paddingRight: "20px"}}>
              <button type="submit" className="submit-btn">Log in</button>
            </div>
            <div>
              <a href={'/signup'} className="submit-btn" style={{textDecoration: "none"}}>
                Create Account
              </a>
            </div>
          </div>  
        </form>
      </div>
    </div>
  );
};

export default LogInView;
